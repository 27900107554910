

*::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
  padding: 4px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid transparent;
  background-clip: content-box;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}